.container {
  display: flex;
  justify-content: space-between;

  .language {
    display: flex;
    column-gap: 10px;
    align-items: center;
    select {
      -webkit-appearance: none;
      cursor: pointer;
    }
    option {
      color: black;
    }
  }

  .theme-container {
    display: flex;
    justify-content: flex-end;

    button {
      width: 1.625rem;
      height: 1.625rem;

      background-image: url('../../assets/static/images/half-moon.png');
      background-repeat: no-repeat;
      background-size: 100%;
    }
  }
}
