@use '../../../globalStyles/mixins' as m;
@use '../../../globalStyles/variables' as v;

.project {
  width: 100%;
  height: 100%;
  display: flex;
  margin-bottom: 1rem;
  justify-content: space-between;
  box-sizing: border-box;
  gap: 1rem;

  .project__details {
    width: 100%;
    display: flex;
    flex: 1 1 90%;

    .item__image {
      min-width: 4rem;
      max-width: 6rem;
      border-radius: v.$border-radius;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 100%;

      img {
        object-fit: contain;
        box-shadow: v.$box-shadow;
        width: 100%;
        height: auto;
        border-radius: v.$border-radius;
        background: m.color(white);
      }
    }

    .tags {
      display: flex;

      .tag {
        @include m.tech-tags;
      }
    }

    .item__details {
      margin-left: 1rem;
      width: 100%;

      .title {
        font-size: m.size(large-0);
        font-weight: m.weight(medium);
        margin: 0 0 0.25rem 0;
      }

      .term {
        font-size: m.size(small-0);
        color: m.color(grey-8);
        margin-left: 2px;
      }

      .attribution {
        font-size: m.size(small-0);
        color: m.color(grey-9);
        margin-top: 0.35rem;
      }
    }

    &:hover {
      opacity: 0.6;
    }
  }

  .project__links {
    width: 100%;
    flex-basis: 5%;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: center;

    .github-logo {
      display: block;
      background-image: url('/assets/static/images/github-light.png');
      background-repeat: no-repeat;
      background-size: contain;
      width: 1.5rem;
      height: 1.5rem;
    }

    &:hover {
      opacity: 0.6;
    }
  }
}

@include m.mobile-down {
  .project {
    flex-direction: column;

    .project__details {
      flex-direction: column;

      .item__image {
        border: 0;
        display: block;
      }

      .item__details {
        margin-top: 0.825rem;
        margin-left: 0.5rem;
      }

      .tags {
        display: flex;
        flex-wrap: wrap;
      }
    }

    .project__links {
      background: #242a2e;
      border-radius: 5px;
      margin-left: 0.5rem;
      cursor: pointer;

      a {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 0.5rem;

        .github-logo {
          background-image: url('../../../assets/static/images/github-dark.png');
        }
      }
    }
  }
}

@include m.mobile {
  .project {
    flex-direction: column;

    .project__details {
      flex-direction: column;

      .item__image {
        border: 0;
        display: block;
      }

      .item__details {
        margin-top: 0.825rem;
        margin-left: 0.5rem;
      }

      .tags {
        display: flex;
        flex-wrap: wrap;
      }
    }

    .project__links {
      background: #242a2e;
      border-radius: 5px;
      margin-left: 0.5rem;
      cursor: pointer;

      a {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 0.5rem;

        .github-logo {
          background-image: url('../../../assets/static/images/github-dark.png');
        }
      }
    }
  }
}
