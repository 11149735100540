.projects-container {
  width: 100%;
  height: 100%;
  padding: 0 1rem;
  flex-direction: column;
  margin-bottom: 5rem;
  box-sizing: border-box;
  display: flex;
  gap: 1rem;
}
